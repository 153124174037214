import { CustomTable } from 'components/CustomTable';
import moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BookingDetailsModal } from '../components';

const CancelBookings = () => {
  const { t } = useTranslation();
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const [pendingBooking, setpendingBooking] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailModal, setDetailModal] = useState({
    open: false,
    booking: null,
  });

  useEffect(() => {
    setLoading(bookinglistdata.bookings ? false : true);
    if (bookinglistdata.bookings) {
      setpendingBooking(
        bookinglistdata.bookings.filter(
          booking => booking.status === 'CANCELLED',
        ),
      );
    }
  }, [bookinglistdata]);

  const handleRowClick = rowData => {
    setDetailModal({
      open: true,
      booking: rowData,
    });
  };

  if (loading && !bookinglistdata.bookings) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div>
      <CustomTable
        onClickRowAction={handleRowClick}
        data={pendingBooking}
        columns={[
          {
            title: 'Fecha y hora',
            field: 'bookingDate',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
            render: rowData =>
              rowData.bookingDate ? (
                <>
                  <p>{moment(rowData.bookingDate).format('DD/MM/YYYY')}</p>
                  <p>{moment(rowData.bookingDate).format('h:mmA')}</p>
                </>
              ) : null,
          },
          {
            title: 'Conductor',
            field: 'driver_name',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Placa',
            field: 'vehicle_number',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Cliente',
            field: 'customer_contact',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
            render: rowData => rowData.customer_contact.replace('+57', ''),
          },
          {
            title: 'Razón de cancelación',
            field: 'reason',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
        ]}
        actions={[]}
      />
      <BookingDetailsModal
        open={detailModal.open}
        closeModal={() => setDetailModal({ open: false, booking: null })}
        booking={detailModal.booking}
        //onClose={() => setDetailModal({ open: false, booking: null })}
      />
    </div>
  );
};

export default CancelBookings;
