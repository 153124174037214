export const FirebaseConfig = {
	"projectId": "taxiok-seed",
	"appId": "1:990401593517:web:83bc52f781442993334624",
	"databaseURL": "https://taxiok-seed-default-rtdb.firebaseio.com",
	"storageBucket": "taxiok-seed.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCWTd8Bk9enX-j4lzSPWBDRQGim3XLGF2s",
	"authDomain": "taxiok-seed.firebaseapp.com",
	"messagingSenderId": "990401593517",
	"measurementId": "G-PKXGRP4YWG"
};