import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CarIcon from '@mui/icons-material/DirectionsCar';
import OfferIcon from '@mui/icons-material/LocalOffer';
import NotifyIcon from '@mui/icons-material/NotificationsActive';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ViewListIcon from '@mui/icons-material/ViewList';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MoneyIcon from '@mui/icons-material/Money';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

import { Roles } from 'models';
import { calcEst } from 'common/sharedFunctions';
import { showEst } from 'common/sharedFunctions';
import { optionsRequired } from 'common/sharedFunctions';

export const MenuOptions = () => {
  const { t } = useTranslation();
  return [
    {
      name: t('home'),
      url: '/',
      icon: <HomeIcon />,
      access: [Roles.ADMIN, Roles.FLEET_ADMIN, Roles.DRIVER, Roles.CUSTOMER],
    },
    {
      name: t('dashboard_text'),
      url: '/dashboard',
      icon: <DashboardIcon />,
      access: [Roles.ADMIN, Roles.FLEET_ADMIN],
    },
    {
      name: t('addbookinglable'),
      url: '/addbookings',
      icon: <ContactPhoneIcon />,
      access:
        calcEst && !showEst && !optionsRequired
          ? [Roles.CUSTOMER]
          : [
              Roles.CUSTOMER,
              Roles.ADMIN,
              Roles.DRIVER,
              Roles.FLEET_ADMIN,
              Roles.CAB_COMPANY,
            ],
    },
    {
      name: t('booking_history'),
      url: '/bookings',
      icon: <ViewListIcon />,
      access: [
        Roles.ADMIN,
        Roles.FLEET_ADMIN,
        Roles.DRIVER,
        Roles.CUSTOMER,
        Roles.CAB_COMPANY,
      ],
    },
    {
      name: t('user'),
      url: '/users',
      icon: <EmojiPeopleIcon />,
      access: [Roles.ADMIN, Roles.FLEET_ADMIN],
    },
    {
      name: t('car_type'),
      url: '/cartypes',
      icon: <CarIcon />,
      access: [Roles.ADMIN],
    },
    {
      name: t('cars'),
      url: '/cars',
      icon: <CarIcon />,
      access: [Roles.ADMIN, Roles.FLEET_ADMIN, Roles.DRIVER],
    },
    {
      name: t('cancellation_reasons'),
      url: '/cancelreasons',
      icon: <CancelScheduleSendIcon />,
      access: [Roles.ADMIN],
    },
    {
      name: t('withdraws'),
      url: '/withdraws',
      icon: <MoneyIcon />,
      access: [Roles.ADMIN],
    },
    {
      name: t('add_to_wallet'),
      url: '/addtowallet',
      icon: <AccountBalanceWalletIcon />,
      access: [Roles.ADMIN],
    },
    {
      name: t('report'),
      url: '/allreports',
      icon: <AssessmentIcon />,
      access: [Roles.ADMIN, Roles.FLEET_ADMIN],
    },
    {
      name: t('settings_title'),
      url: '/settings',
      icon: <PhoneIphoneIcon />,
      access: [Roles.ADMIN],
    },
    {
      name: t('promo'),
      url: '/promos',
      icon: <OfferIcon />,
      access: [Roles.ADMIN],
    },
    {
      name: t('push_notification_title'),
      url: '/notifications',
      icon: <NotifyIcon />,
      access: [Roles.ADMIN],
    },
    {
      name: t('my_wallet_tile'),
      url: '/userwallet',
      icon: <AccountBalanceWalletIcon />,
      access: [Roles.DRIVER, Roles.CUSTOMER],
    },
    {
      name: t('profile'),
      url: '/profile',
      icon: <AccountCircleIcon />,
      access: [
        Roles.ADMIN,
        Roles.FLEET_ADMIN,
        Roles.DRIVER,
        Roles.CUSTOMER,
        Roles.CAB_COMPANY,
      ],
    },
  ];
};
