import { api } from 'common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAvilableDrivers = () => {
  const usersdata = useSelector(state => state.usersdata);
  const cars = useSelector(state => state.cartypes.cars);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();

  const { GetDistance, fetchBookingTracking } = api;

  const [drivers, setDrivers] = useState();
  const [freeCars, setFreeCars] = useState([]);

  const getAvalibleDrivers = async pickupArgs => {
    let arr = {};
    let distArr = [];

    let availableDrivers = drivers.filter(driver => {
      if (!driver.location) return false;
      const distance = GetDistance(
        pickupArgs.latitude,
        pickupArgs.longitude,
        driver.location.lat,
        driver.location.lng,
      );
      driver['distance'] = distance;
      return settings.driverRadius >= distance;
    });

    const sortedDrivers = availableDrivers;

    if (sortedDrivers.length === 0) return [];

    sortedDrivers.forEach(driver => {
      distArr.push({
        timein_text: (driver.distance * 2 + 1).toFixed(0) + ' min',
        found: true,
      });
    });

    for (let i = 0; i < distArr.length; i++) {
      let driver = sortedDrivers[i];
      if (cars) {
        driver.arriveTime = distArr[i];
        for (let i = 0; i < cars.length; i++) {
          driver.carImage = cars[i].image;
        }
        let carType = driver.carType;
        if (carType && carType.length > 0) {
          if (arr[carType] && arr[carType].sortedDrivers) {
            arr[carType].sortedDrivers.push(driver);
            if (arr[carType].minDistance > driver.distance) {
              arr[carType].minDistance = driver.distance;
              arr[carType].minTime = driver.arriveTime.timein_text;
            }
          } else {
            arr[carType] = {};
            arr[carType].sortedDrivers = [];
            arr[carType].sortedDrivers.push(driver);
            arr[carType].minDistance = driver.distance;
            arr[carType].minTime = driver.arriveTime.timein_text;
          }
        }
        availableDrivers.push(driver);
      }
    }
    setFreeCars(availableDrivers);
  };

  const getDriver = driverId => {
    return drivers.filter(driver => {
      if (!driver.location) return false;
      return driver.id === driverId;
    });
  };

  useEffect(() => {
    if (usersdata.users) setDrivers(usersdata.users);
  }, [usersdata.users]);

  return {
    getDriver,
    getAvalibleDrivers,
    freeCars,
  };
};

export default useAvilableDrivers;
