import { CustomTable } from 'components/CustomTable';
import moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BookingDetailsModal } from '../components';
import { Box, Button, Modal, Typography } from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import useStyles from 'styles/styles';
import { api } from 'common';

const AcceptedBooking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { forceEndBooking, updateBooking } = api;

  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const [pendingBooking, setpendingBooking] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [detailModal, setDetailModal] = useState({
    open: false,
    booking: null,
  });

  const [forceEndModal, setForceEndModal] = useState({
    open: false,
    booking: null,
  });

  useEffect(() => {
    setLoading(bookinglistdata.bookings ? false : true);
    if (bookinglistdata.bookings) {
      setpendingBooking(
        bookinglistdata.bookings.filter(
          booking => booking.status === 'STARTED',
        ),
      );
    }
  }, [bookinglistdata]);

  const handleRowClick = rowData => {
    setDetailModal({
      open: true,
      booking: rowData,
    });
  };

  const endBooking = bookingRef => {
    setLoading(true);
    let booking = { ...bookingRef };
    booking.status = 'COMPLETE';
    dispatch(updateBooking(booking));
  };

  if (loading && !bookinglistdata.bookings) {
    return <div>{t('loading')}</div>;
  }

  return (
    <Box>
      <CustomTable
        onClickRowAction={handleRowClick}
        data={pendingBooking}
        columns={[
          {
            title: 'Placa',
            field: 'vehicle_number',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Conductor',
            field: 'driver_name',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
            render: rowData => (
              <>
                <p>{rowData.driver_name}</p>
                <p>{rowData.driver_contact.replace('+57', '')}</p>
              </>
            ),
          },
          {
            title: 'Pasajero',
            field: 'customer_contact',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
            render: rowData => (
              <>
                <p>{rowData.customer_name}</p>
                <p>{rowData.customer_contact.replace('+57', '')}</p>
              </>
            ),
          },
          {
            title: 'Origen',
            field: 'pickupAddress',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Destino',
            field: 'dropAddress',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
        ]}
        actions={[
          rowData => ({
            icon: () => (
              <div
                className={classes.action}
                style={{
                  width: '11rem',
                  backgroundColor: '#C82333',
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  paddingBlock: '0.5rem',
                  paddingInline: '1rem',
                  borderRadius: '5px',
                }}
              >
                <PlaylistAddCheckIcon />
                <Typography variant="subtitle2">{t('force_end')}</Typography>
              </div>
            ),
            onClick: (event, rowData) => {
              setForceEndModal({
                open: true,
                booking: rowData,
              });
            },
          }),
        ]}
      />

      <Modal
        open={forceEndModal.open}
        keepMounted
        onClose={() => setForceEndModal({ open: false, booking: null })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40vw',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: 'auto',
          }}
        >
          <div>
            <Typography textAlign={'center'}>
              ¿ESTA SEGURO DE FORZAR LA FINALIZACIÓN DEL SERVICIO?
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: 5,
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => setForceEndModal({ open: false, booking: null })}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  endBooking(forceEndModal.booking);
                  setForceEndModal({ open: false, booking: null });
                }}
              >
                Aceptar
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <BookingDetailsModal
        open={detailModal.open}
        closeModal={() => setDetailModal({ open: false, booking: null })}
        booking={detailModal.booking}
        //onClose={() => setDetailModal({ open: false, booking: null })}
      />
    </Box>
  );
};

export default AcceptedBooking;
