import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { CustomInputAtom } from 'components/Atoms';
import React from 'react';

const CustomAutoComplete = ({
  label,
  placeholder,
  mandatory = false,
  icon = null,
  value,
  updateValue,
  updateInputValue,
  options,
  type,
  props,
}) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginBottom: 4,
        }}
      >
        {icon && icon}
        <Typography variant="body1" color={'GrayText'} textTransform={'capitalize'}>
          {label}
        </Typography>
      </div>
      <Autocomplete
        freeSolo
        id="user-select"
        autoHighlight
        value={value ? value : ''}
        options={options}
        onChange={(event, newValue) => {
          updateValue(newValue);
        }}
        getOptionLabel={option => option['description'] || option}
        isOptionEqualToValue={(option, value) => option.description === value.description}
        renderInput={params => (
          <TextField
            {...params}
            label={''}
            placeholder={placeholder}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              //autoComplete: 'off',
            }}
            onChange={text => {
              updateInputValue(text.target.value);
            }}
          />
        )}
      />
    </div>
  );
};

export default CustomAutoComplete;
