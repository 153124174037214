import MaterialTable from 'material-table';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomTable = ({ columns, data, actions, onClickRowAction }) => {
  const { t } = useTranslation();

  return (
    <MaterialTable
      title={t('booking_title')}
      style={{
        borderRadius: '8px',
      }}
      columns={columns}
      data={data}
      onRowClick={(evt, selectedRow) => {
        onClickRowAction && onClickRowAction(selectedRow);
      }}
      localization={{
        toolbar: {
          searchPlaceholder: t('search'),
          exportTitle: t('export'),
          exportCSVName: t('export'),
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} ' + t('of') + ' {count}',
          firstTooltip: t('first_page_tooltip'),
          previousTooltip: t('previous_page_tooltip'),
          nextTooltip: t('next_page_tooltip'),
          lastTooltip: t('last_page_tooltip'),
        },
        header: {
          actions: 'Acciones',
        },
      }}
      actions={[
        {
          icon: 'add',
          tooltip: 'Añadir nueva reserva',
          isFreeAction: true,
          onClick: event => alert('You want to add a new row'),
        },
        ...actions,
      ]}
      options={{
        rowStyle: {
          marginBottom: '10px',
          padding: 20,
          borderRadius: 8,
          maxWidth: 100,
        },
      }}
    />
  );
};

export default CustomTable;
