import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { downloadCsv } from '../../common/sharedFunctions';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { bookingHistoryColumns } from '../../common/sharedFunctions';
import { CustomDateRange } from 'components/Molucules';
import { CustomTabs } from 'components/CustomTabs';
import { AcceptedBooking } from './AcceptedBookings';
import { WaitingBookings } from './WaitingBooking';
import { EndedBookings } from './EndedBookings';
import { CancelBookings } from './CancelBookings';
import { PendingBookings } from './PendingBookings';

const BookingHistoryPage = props => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector(state => state.auth);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const role = useSelector(state => state.auth.profile.usertype);

  const columns = bookingHistoryColumns(role, settings, t, isRTL);

  const generateReportExcel = (fromDate, untilDate) => {
    let headersName = [];
    const headers = columns.map(col => {
      if (typeof col.title === 'object') {
        return col.title.props.text;
      }
      headersName.push(col.field);
      return col.title;
    });

    const rows = bookinglistdata.bookings.filter(({ tableData, ...row }) => {
      return (
        new Date(row.bookingDate).getTime() >= fromDate.getTime() &&
        new Date(row.bookingDate).getTime() <= untilDate.getTime()
      );
    });

    const dataRows = rows.map(({ tableData, ...row }) => {
      row.id = row.id.replace('-', '');
      row.bookingDate =
        new Date(row.bookingDate).toLocaleDateString() +
        ' ' +
        new Date(row.bookingDate).toLocaleTimeString();
      row.tripdate =
        new Date(row.tripdate).toLocaleDateString() +
        ' ' +
        new Date(row.tripdate).toLocaleTimeString();
      row.pickupAddress = row.pickupAddress.replace(/,/g, ';');
      row.dropAddress = row.dropAddress.replace(/,/g, ';');
      let dArr = [];
      for (let i = 0; i < headersName.length; i++) {
        const value = row[headersName[i]];
        dArr.push(value ? value : 'null');
      }
      const data = Object.values(dArr);
      return data;
    });
    const { exportDelimiter } = ';';
    const delimiter = exportDelimiter ? exportDelimiter : ';';
    const csvContent = [headers, ...dataRows]
      .map(e => e.join(delimiter))
      .join('\n');

    const csvFileName = `${
      auth.profile.firstName
    }_${new Date().toLocaleDateString()}.csv`;
    downloadCsv(csvContent, csvFileName);
  };

  // bookinglistdata.loading ? (
  //   <CircularLoading />
  // ) :

  return (
    <div
      style={{
        minWidth: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '3rem',
        }}
      >
        <Typography variant="h5">Historial de reservas</Typography>
        <CustomDateRange
          actionText={'Generar reporte'}
          onActionClick={generateReportExcel}
        />
      </div>
      <CustomTabs
        headers={[
          'Pendientes',
          'Aceptadas',
          'En curso',
          'Finalizadas',
          'Canceladas',
        ]}
        content={[
          <PendingBookings />,
          <WaitingBookings />,
          <AcceptedBooking />,
          <EndedBookings />,
          <CancelBookings />,
        ]}
      />
    </div>
  );
};

export default BookingHistoryPage;
