import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import ExitIcon from '@mui/icons-material/ExitToApp';

import { api } from 'common';
import { useTranslation } from 'react-i18next';

import { SimpleLayout } from 'layouts';
import { MenuItem } from './MenuItem';

import { colors } from 'components/Theme/WebTheme';
import { MenuOptions } from './MenuOptions';
import { Button } from '@mui/material';
import MenuComponent, { drawerWidth } from './MenuComponent';

export default function ResponsiveDrawer(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { signOff } = api;
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();
  const options = MenuOptions();

  const LogOut = () => {
    dispatch(signOff());
  };

  const [role, setRole] = useState(null);

  useEffect(() => {
    if (auth.profile && auth.profile.usertype) {
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerImg = auth.profile.profile_image
    ? auth.profile.profile_image
    : require('../../assets/img/logo192x192.png').default;

  const drawer = (
    <div
      style={{
        backgroundColor: colors.Header,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            backgroundColor: colors.Header,
            justifyContent: 'center',
          }}
        >
          <img
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              width: 'auto',
              height: '120px',
            }}
            src={drawerImg}
            alt="Logo"
          />
        </div>
        <div style={{ backgroundColor: colors.Header }}>
          {role && (
            <div>
              {options.map(
                item =>
                  item.access.includes(role) && (
                    <MenuItem item={item} key={item} />
                  ),
              )}
            </div>
          )}
        </div>
      </div>

      <div>
        <Button onClick={LogOut} startIcon={<ExitIcon />}>
          {t('logout')}
        </Button>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <MenuComponent children={<SimpleLayout>{props.children}</SimpleLayout>} />
    </Box>
  );
}
