import Holidays from "date-holidays";
import moment from "moment";

export const FareCalculator = (
  distance,
  time,
  rateDetails,
  instructionData,
  decimal,
  is_in_airport,
  is_in_transport_station
) => {
  const hd = new Holidays("CO");
  let baseCalculated =
    parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance) +
    parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600);
  if (rateDetails.base_fare > 0) {
    baseCalculated = baseCalculated + rateDetails.base_fare;
  }
  if (instructionData && instructionData.parcelTypeSelected) {
    baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
  }
  if (instructionData && instructionData.optionSelected) {
    baseCalculated = baseCalculated + instructionData.optionSelected.amount;
  }
  let total =
    baseCalculated > parseFloat(rateDetails.min_fare)
      ? baseCalculated
      : parseFloat(rateDetails.min_fare);
  let convenienceFee = 0;
  if (
    rateDetails.convenience_fee_type &&
    rateDetails.convenience_fee_type == "flat"
  ) {
    convenienceFee = rateDetails.convenience_fees;
  } else {
    convenienceFee = (total * parseFloat(rateDetails.convenience_fees)) / 100;
  }
  let grand = total + convenienceFee;

  // Validations if colombian holiday we will charge 1100 pesos extra
  const isHoliday = hd.isHoliday(new Date());
  const isSunday = moment().day() === 0;
  if ((isHoliday || isSunday) && rateDetails?.holiday_fee) {
    grand = grand + rateDetails?.holiday_fee || 0;
  }

  if (is_in_airport && rateDetails?.airport_fee) {
    grand = grand + rateDetails?.airport_fee || 0;
  }

  // Validation to add night fee if the hour is between 8:01pm and 4:59am
  const hour = moment().hour();
  if ((hour >= 20 || hour < 5) && rateDetails?.night_fee) {
    grand = grand + rateDetails?.night_fee || 0;
  }

  if (is_in_transport_station && rateDetails?.transport_station_fee) {
    grand = grand + rateDetails?.transport_station_fee || 0;
  }


  return {
    totalCost: parseFloat(total.toFixed(decimal)),
    grandTotal: parseFloat(grand.toFixed(decimal)),
    convenience_fees: parseFloat(convenienceFee.toFixed(decimal)),
  };
};
