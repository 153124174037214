import {
  FETCH_ESTIMATE,
  FETCH_ESTIMATE_SUCCESS,
  FETCH_ESTIMATE_FAILED,
  CLEAR_ESTIMATE,
} from '../store/types';
import Polyline from '@mapbox/polyline';
import { firebase } from '../config/configureFirebase';
import { FareCalculator } from '../other/FareCalculator';
import { onValue } from 'firebase/database';

import stationsData from '../const/transport-stations.json';
import airportsData from '../const/airports.json';

const { stations } = stationsData;
const { airports } = airportsData;

const roundUpToNearest500 = number => {
  return Math.ceil(number / 500) * 500;
};

const calculateHaversineDistance = (coord1, coord2) => {
  const earthRadius = 6371; // Radius of the Earth in kilometers
  const lat1 = coord1[0];
  const lon1 = coord1[1];
  const lat2 = coord2[0];
  const lon2 = coord2[1];

  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance;
};

export const getEstimate = bookingData => async dispatch => {
  const { settingsRef } = firebase;

  dispatch({
    type: FETCH_ESTIMATE,
    payload: bookingData,
  });

  let res = bookingData.routeDetails;

  if (res) {
    let points = Polyline.decode(res.polylinePoints);
    let waypoints = points.map(point => {
      return {
        latitude: point[0],
        longitude: point[1],
      };
    });

    const initial_coordinates = points[0];
    const final_coordinates = points[points.length - 1];
    let is_in_airport = false;
    let is_in_transport_station = false;

    for (let i = 0; i < airports.length; i++) {
      const airport = airports[i];
      const airport_coordinates = [airport.lat, airport.lng];
      const initial_distance = calculateHaversineDistance(
        airport_coordinates,
        initial_coordinates,
      );
      const final_distance = calculateHaversineDistance(
        airport_coordinates,
        final_coordinates,
      );
      if (initial_distance <= 0.1 || final_distance <= 0.1) {
        is_in_airport = true;
        break;
      }
    }

    for (let i = 0; i < stations.length; i++) {
      const station = stations[i];
      const station_coordinates = [station.lat, station.lng];
      const initial_distance = calculateHaversineDistance(
        station_coordinates,
        initial_coordinates,
      );
      const final_distance = calculateHaversineDistance(
        station_coordinates,
        final_coordinates,
      );
      if (initial_distance <= 0.1 || final_distance <= 0.1) {
        is_in_transport_station = true;
        break;
      }
    }

    onValue(
      settingsRef,
      settingdata => {
        let settings = settingdata.val();
        let distance = settings.convert_to_mile
          ? res.distance_in_km / 1.609344
          : res.distance_in_km;

        let { totalCost, grandTotal, convenience_fees } = FareCalculator(
          distance,
          res.time_in_secs,
          bookingData.carDetails,
          bookingData.instructionData,
          settings.decimal,
          is_in_airport,
          is_in_transport_station,
        );

        dispatch({
          type: FETCH_ESTIMATE_SUCCESS,
          payload: {
            pickup: bookingData.pickup,
            drop: bookingData.drop,
            carDetails: bookingData.carDetails,
            instructionData: bookingData.instructionData,
            estimateDistance: parseFloat(distance).toFixed(settings.decimal),
            fareCost: totalCost
              ? parseFloat(totalCost).toFixed(settings.decimal)
              : 0,
            estimateFare: grandTotal
              ? roundUpToNearest500(
                  parseFloat(grandTotal).toFixed(settings.decimal),
                )
              : 0,
            estimateTime: res.time_in_secs,
            convenience_fees: convenience_fees
              ? parseFloat(convenience_fees).toFixed(settings.decimal)
              : 0,
            waypoints: waypoints,
            is_in_airport: is_in_airport,
          },
        });
      },
      { onlyOnce: true },
    );
  } else {
    dispatch({
      type: FETCH_ESTIMATE_FAILED,
      payload: 'No Route Found',
    });
  }
};

export const clearEstimate = () => dispatch => {
  dispatch({
    type: CLEAR_ESTIMATE,
    payload: null,
  });
};
