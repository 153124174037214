import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import { GoogleMapApiConfig } from 'config/GoogleMapApiConfig';
import { CustomMapMark } from '.';
import { useAvilableDrivers } from '../hooks';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import PlaceIcon from '@mui/icons-material/Place';
import CloseIcon from '@mui/icons-material/Close';

const BookingDetailsModal = ({ booking, open, closeModal }) => {
  const { getAvalibleDrivers, freeCars, getDriver } = useAvilableDrivers();

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 14,
  };

  const calculateMapCenter = () => {
    const lat = (booking.pickup.lat + booking.drop.lat) / 2;
    const lng = (booking.pickup.lng + booking.drop.lng) / 2;
    return {
      lat,
      lng,
    };
  };

  const getMinutesAndSeconds = time => {
    return (Number(time) / 60).toFixed(2).split('.');
  };

  const handleApiLoaded = (map, maps) => {
    const markers = [];
    const infowindows = [];
  };

  const selectTitle = status => {
    switch (status) {
      case 'NEW':
        return 'PENDIENTE';
      case 'ACCEPTED':
        return 'ACEPTADO';
      case 'STARTED':
        return 'EN CURSO';
      case 'COMPLETE':
        return 'FINALIZADO';
      case 'CANCELLED':
        return 'CANCELADO';
      default:
        return 'DETALLES';
    }
  };

  useEffect(() => {
    if (!booking) return;
    getAvalibleDrivers({
      latitude: booking.pickup.lat,
      longitude: booking.pickup.lng,
    });
  }, [booking]);

  if (!booking) {
    return null;
  }

  return (
    <Modal
      open={open}
      keepMounted
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw',
          height: '95vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: 'auto',
        }}
      >
        {/* <div>{JSON.stringify(booking)}</div> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            position: 'relative',
            height: '10%',
          }}
        >
          <Typography fontWeight={'bold'} textAlign={'center'} variant="h5">
            SERVICIO {selectTitle(booking.status)}
          </Typography>

          <IconButton
            sx={{
              position: 'absolute',
              right: -20,
              top: -20,
            }}
            onClick={closeModal}
          >
            <CloseIcon sx={{ color: 'red' }} />
          </IconButton>
        </div>

        <Grid
          container
          sx={{
            minHeight: '90%',
          }}
        >
          <Grid item xs={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'start',
                rowGap: 2,
              }}
            >
              {booking.status !== 'NEW' && (
                <>
                  <div>
                    <Typography fontWeight={'bold'}>DATOS CONDUCTOR</Typography>
                    <Typography>{booking.driver_name}</Typography>
                    <Typography>{booking.driver_contact}</Typography>
                  </div>
                </>
              )}

              <div>
                <Typography fontWeight={'bold'}>DATOS PASAJERO</Typography>
                <Typography>{booking.customer_name}</Typography>
                <Typography>{booking.customer_contact}</Typography>
              </div>

              <div>
                <Typography fontWeight={'bold'}>FECHA</Typography>
                <Typography>
                  {moment(booking.bookingDate).format('DD/MM/YYYY - h:mmA')}
                </Typography>
              </div>

              <div>
                <Typography fontWeight={'bold'}>
                  PARÁMETROS ESTIMADOS
                </Typography>
                <Typography>{booking.estimateDistance}km</Typography>
                <Typography>
                  {getMinutesAndSeconds(booking.estimateTime)[0]}
                  min - {getMinutesAndSeconds(booking.estimateTime)[1]}s
                </Typography>
              </div>

              {booking.status === 'STARTED' && (
                <div>
                  <Typography fontWeight={'bold'}>
                    DATOS DEL RECORRIDO
                  </Typography>
                  <Typography>
                    Hora inicio de recorrido: {booking.trip_start_time}
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <PlaceIcon sx={{ color: 'green' }} />
                    <Typography>{booking.pickupAddress}</Typography>
                  </Box>
                </div>
              )}

              {booking.status === 'COMPLETE' && (
                <div>
                  <Typography fontWeight={'bold'}>
                    DATOS DEL RECORRIDO
                  </Typography>
                  <Typography>Inicio: {booking.trip_start_time}</Typography>
                  <Typography>Fin: {booking.trip_end_time}</Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Typography>
                      Duración:{'\t\t\t'}
                      {getMinutesAndSeconds(booking.total_trip_time)[0]}
                      min - {getMinutesAndSeconds(booking.total_trip_time)[1]}s
                    </Typography>
                    <Typography></Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <PlaceIcon sx={{ color: 'green' }} />
                    <Typography>{booking.pickupAddress}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <SportsScoreIcon />
                    <Typography>{booking.dropAddress}</Typography>
                  </Box>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: '#0A6AAD',
                  width: '100%',
                  paddingRight: '2rem',
                  borderRadius: '5px',
                  padding: '1rem',
                  color: '#fff',
                }}
              >
                <Typography fontWeight={'bold'}>COSTO</Typography>
                <Typography>${booking.trip_cost} COP</Typography>
              </div>

              {booking.status === 'CANCELLED' && (
                <>
                  <div>
                    <Typography fontWeight={'bold'}>
                      DATOS DE CANCELACIÓN
                    </Typography>
                    <Typography>
                      Cancelado por: {booking.cancelledBy}
                    </Typography>
                    <Typography>Motivo: {booking.reason}</Typography>
                  </div>
                </>
              )}
            </Box>
          </Grid>

          {booking.status !== 'CANCELLED' && (
            <Grid item xs={9}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: GoogleMapApiConfig }}
                defaultCenter={calculateMapCenter()}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
              >
                {CustomMapMark.createLocationMarket({
                  lat: booking.pickup.lat,
                  lng: booking.pickup.lng,
                  title: 'A' /* road circle */,
                })}

                {CustomMapMark.createLocationMarket({
                  lat: booking.drop.lat,
                  lng: booking.drop.lng,
                  title: 'B' /* road circle */,
                })}

                {booking.status === 'NEW' &&
                  freeCars.map(driver => {
                    return CustomMapMark.createTaxiMarket({
                      lat: driver.location.lat,
                      lng: driver.location.lng,
                      title: 'taxi' /* road circle */,
                      driverData: driver,
                    });
                  })}

                {booking.status === 'ACCEPTED' &&
                  getDriver(booking.driver).map(driver => {
                    return CustomMapMark.createTaxiMarket({
                      lat: driver.location.lat,
                      lng: driver.location.lng,
                      title: 'taxi' /* road circle */,
                      driverData: driver,
                    });
                  })}
                {booking.status === 'STARTED' &&
                  getDriver(booking.driver).map(driver => {
                    return CustomMapMark.createTaxiMarket({
                      lat: driver.location.lat,
                      lng: driver.location.lng,
                      title: 'taxi' /* road circle */,
                      driverData: driver,
                      taxiStatus: 'bussy',
                    });
                  })}
              </GoogleMapReact>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default BookingDetailsModal;
