import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { colors } from 'components/Theme/WebTheme';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ marginTop: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 *
 * @param {*} headers Titulos de los headers de las tabs
 * @param {*} content Contenido de las tabs, cada elemento del array es el contenido de una tab, el orden es el mismo que el de los headers
 * @returns
 */
export default function CustomTabs({ headers, content }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{}}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          flexGrow: 1,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            backgroundColor: '#DADADB',
            borderRadius: 2,
            '& .MuiTabs-indicator': {
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'transparent',
            },
            '& .MuiTabs-indicatorSpan': {
              maxWidth: 40,
              width: '100%',
              backgroundColor: '#635ee7',
            },
          }}
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
        >
          {headers.map((header, index) => (
            <Tab
              label={header}
              {...a11yProps(index)}
              key={header}
              sx={{
                borderRadius: 2,
                borderWidth: 0,
                '&.Mui-selected': {
                  color: '#000',
                  backgroundColor: colors.CAR_BOX_ODD,
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {content.map((content, index) => (
        <CustomTabPanel value={value} index={index} key={index}>
          {content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
