import { TextField } from '@mui/material';

const CustomInputAtom = ({
  placeholder,
  size,
  type = 'text',
  updateText,
  value,
  defaultValue,
  props = {},
}) => {
  const onChange = e => {
    if (updateText) {
      updateText(e.target.value);
    }
  };

  return (
    <div>
      <TextField
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        style={{
          width: '100%',
        }}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default CustomInputAtom;
