import { Typography } from '@mui/material';
import { CustomInputAtom } from 'components/Atoms';

const LabeledInput = ({
  label,
  placeholder,
  mandatory = false,
  icon = null,
  value,
  updateText,
  options,
  type,
  props,
}) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginBottom: 4,
        }}
      >
        {icon && icon}
        <Typography variant="body1" color={'GrayText'} textTransform={'capitalize'}>
          {label}
        </Typography>
      </div>
      <CustomInputAtom
        placeholder={placeholder}
        size={'large'}
        type={type}
        props={props}
        updateText={updateText}
      />
    </div>
  );
};

export default LabeledInput;
