import React, { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { LabeledText } from './Molucules';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  iconRtl: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
  },
  inputRtl: {
    '& label': {
      right: 75,
      left: 'auto',
    },
    '& legend': {
      textAlign: 'right',
      marginRight: 60,
    },
  },
}));

export default function GoogleMapsAutoComplete(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [addressInput, setAddressInput] = useState('');
  const [options, setOptions] = useState([]);

  const fetchAddressData = useMemo(() => {
    return async () => {
      if (addressInput.length === 0) {
        return;
      }
      let address = addressInput;
      address = address.includes('#') ? address.replace('#', '%23') : address;
      const urlRequest = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}`;
      const additionalOptions = `&components=country:CO|locality:Cúcuta&key=AIzaSyCWTd8Bk9enX-j4lzSPWBDRQGim3XLGF2s`;
      try {
        const response = await fetch(urlRequest + additionalOptions);
        const data = await response.json();

        if (data.results.length > 0) {
          setOptions({
            coords: {
              lat: data.results[0].geometry.location.lat,
              lng: data.results[0].geometry.location.lng,
            },
            addressName: data.results[0].formatted_address,
            add: data.results[0].formatted_address,
            description: data.results[0].formatted_address,
          });
          props.onChange({
            coords: {
              lat: data.results[0].geometry.location.lat,
              lng: data.results[0].geometry.location.lng,
            },
            addressName: data.results[0].formatted_address,
            description: data.results[0].formatted_address,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
  }, [addressInput]);

  useEffect(() => {
    fetchAddressData();
  }, [fetchAddressData]);

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'}>
        {props.icon}
        <Typography
          variant="body1"
          color={'GrayText'}
          textTransform={'capitalize'}
        >
          {props.placeholder}
        </Typography>
      </Box>

      <TextField
        fullWidth
        placeholder={t('pickup_location')}
        onChange={e => setAddressInput(e.target.value)}
        value={props.externalValue ? props.externalValue : addressInput}
      />
    </Box>
  );
}
