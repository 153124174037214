const K_WIDTH = 40;
const K_HEIGHT = 40;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: '5px solid #f44336',
  borderRadius: K_HEIGHT,
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 20,
  fontWeight: 'bold',
  padding: 4,
};

const taxiPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH / 1.1,
  height: K_HEIGHT / 1.1,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  //   border: '5px solid #f44336',
  //   borderRadius: K_HEIGHT,
  //   backgroundColor: 'white',
  //   textAlign: 'center',
  //   color: '#3f51b5',
  //   fontSize: 16,
  //   fontWeight: 'bold',
  padding: 4,
};

const infoWindowStyle = {
  position: 'relative',
  bottom: 60,
  minWidth: 150,
  backgroundColor: 'white',
  boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
  padding: 10,
  fontSize: 20,
  zIndex: 100,
  borderRadius: 10,
};

export { greatPlaceStyle, taxiPlaceStyle, infoWindowStyle };
