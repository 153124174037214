import { Box, Button, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

const CustomDateRange = ({ actionText, onActionClick }) => {
  const currentDate = dayjs(new Date());
  const [fromDate, setFromDate] = useState(currentDate.subtract(1, 'day'));
  const [untilDate, setUntilDate] = useState(currentDate);

  const validatePickedDates = () => {};

  const onClick = () => {
    validatePickedDates();
    onActionClick(fromDate.toDate(), untilDate.toDate());
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          inputFormat="DD/MM/YYYY"
          label="DESDE"
          value={fromDate}
          onChange={newValue => {
            setFromDate(newValue);
          }}
          renderInput={params => <TextField {...params} />}
          maxDate={untilDate.subtract(1, 'day')} // la maxima fecha no puede ser mayor a la fecha de fin
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          inputFormat="DD/MM/YYYY"
          label="HASTA"
          value={untilDate}
          onChange={newValue => {
            setUntilDate(newValue);
          }}
          renderInput={params => <TextField {...params} />}
          maxDate={currentDate} // la maxima fecha es la fecha actual
          minDate={fromDate.add(1, 'day')} // la fecha minima no puede ser menor a la fecha de inicio
        />
      </LocalizationProvider>
      <Button
        variant="contained"
        endIcon={<PendingActionsIcon />}
        onClick={onClick}
      >
        {actionText}
      </Button>
    </Box>
  );
};

export default CustomDateRange;
