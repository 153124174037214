import { Box, Container, Typography } from '@mui/material';

const LabeledText = ({ label, icon = null, text, props }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginBottom: 4,
        }}
      >
        {icon && icon}
        <Typography variant="body1" color={'GrayText'} textTransform={'capitalize'}>
          {label}
        </Typography>
      </div>
      <Container
        sx={{
          border: '1px solid #d6d6d6',
          borderRadius: 1,
          paddingBlock: 2,
        }}
      >
        <Typography variant="body1" textTransform={'capitalize'}>
          {text}
        </Typography>
      </Container>
    </div>
  );
};

export default LabeledText;
