import {
  FETCH_CARS,
  FETCH_CARS_SUCCESS,
  FETCH_CARS_FAILED,
  EDIT_CAR,
} from '../store/types';
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, update, set, child, remove, push } from 'firebase/database';
import { uploadBytes, getDownloadURL } from 'firebase/storage';

export const fetchCars = () => dispatch => {
  const { carsRef } = firebase;

  dispatch({
    type: FETCH_CARS,
    payload: null,
  });

  const userInfo = store.getState().auth.profile;

  onValue(carsRef(userInfo.uid, userInfo.usertype), snapshot => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i;
        return data[i];
      });
      dispatch({
        type: FETCH_CARS_SUCCESS,
        payload: arr,
      });
    } else {
      dispatch({
        type: FETCH_CARS_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_cars,
      });
    }
  });
};

export const addCar = car => async dispatch => {
  const { carAddRef } = firebase;
  const method = 'Add';
  dispatch({
    type: EDIT_CAR,
    payload: { method, car },
  });

  // Agregar el carro a la base de datos
  const newCarRef = push(carAddRef, car);

  // Obtener la clave única del carro recién agregado
  const newCarKey = newCarRef.key;
  return newCarKey;
};

export const editCar = (car, method) => async dispatch => {
  const { singleUserRef, carAddRef, carEditRef, carImage } = firebase;
  dispatch({
    type: EDIT_CAR,
    payload: { method, car },
  });

  if (method === 'Add') {
    push(carAddRef, car);
  } else if (method === 'Delete') {
    remove(carEditRef(car.id));
  } else if (method === 'UpdateImage') {
    await uploadBytes(carImage(car.id), car.car_image);
    let image = await getDownloadURL(carImage(car.id));
    let data = car;
    data.car_image = image;
    set(carEditRef(car.id), data);
    if (car.active && car.driver) {
      update(singleUserRef(car.driver), {
        updateAt: new Date().getTime(),
        car_image: image,
      });
    }
  } else {
    set(carEditRef(car.id), car);
  }
};

export const updateUserCarWithImage =
  (newData, blob, refId = null) =>
  async dispatch => {
    const { carImage, carEditRef, carsRef, singleUserRef } = firebase;

    let uid = newData.id ? newData.id : null;

    if (uid === null) {
      onValue(carsRef(userInfo.uid, userInfo.usertype), snapshot => {
        if (snapshot.val()) {
          let data = snapshot.val();
          const arr = Object.keys(data).map(i => {
            data[i].id = i;
            return data[i];
          });
          uid = arr.find(car => car.vehicleNumber === newData.vehicleNumber)[0]
            .id;
        }
      });
    }

    try {
      const reponse = await uploadBytes(carImage(uid), blob);
      const imgUrl = await getDownloadURL(carImage(uid));
      if (newData.active) {
        let updateData = {
          ...newData,
          car_image: imgUrl,
          updateAt: new Date().getTime(),
        };

        await update(singleUserRef(newData.driver), {
          carId: uid,
          carType: newData.carType,
          vehicleNumber: newData.vehicleNumber,
          vehicleMake: newData.vehicleMake,
          vehicleModel: newData.vehicleModel,
          other_info: newData.other_info,
          car_image: imgUrl,
          carApproved: newData.approved,
          vehicleCity: newData.vehicleCity,
          updateAt: new Date().getTime(),
        });
        await update(carEditRef(uid), updateData);
      }
    } catch (error) {
      console.error(error);
    }
  };

export const updateCarData = newCarData => async dispatch => {
  const { carImage, carEditRef, carsRef, singleUserRef } = firebase;

  let uid = newCarData.id ? newCarData.id : null;

  try {
    let updateData = {
      carId: uid,
      carType: newCarData.carType,
      vehicleNumber: newCarData.vehicleNumber,
      vehicleMake: newCarData.vehicleMake,
      vehicleModel: newCarData.vehicleModel,
      other_info: newCarData.other_info,
      carApproved: newCarData.approved,
      vehicleCity: newCarData.vehicleCity,
      updateAt: new Date().getTime(),
    };

    if (newCarData.carImgBlob) {
      await uploadBytes(carImage(uid), newCarData.carImgBlob);
      const imgUrl = await getDownloadURL(carImage(uid));
      updateData.car_image = imgUrl;
    }

    await update(singleUserRef(newCarData.driver), updateData);
    await update(carEditRef(uid), updateData);
  } catch (error) {
    console.error(error);
  }
};
