import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';

const SimpleLayout = ({ children }) => {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        padding: 5,
        width: '100%',
        position: 'relative',
        //maxWidth: 'calc(100vw - 330px) !important',
      }}
    >
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default SimpleLayout;
