import { Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { colors } from 'components/Theme/WebTheme';
import { NavLink } from 'react-router-dom';
import './MenuDrawer.styles.css';

export const MenuItem = item => {
  return (
    <div
      style={{
        display: 'flex',
        height: '50px',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <NavLink
        to={`${item.item.url}`}
        type="button"
        onClick={() => {
          item.item.function();
        }}
        style={{
          textDecoration: 'none',
          width: '100%',
          height: '100%',
          '&:hover': {
            backgroundColor: '#f00',
          },
        }}
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? 'active' : ''
        }
      >
        <ListItem
          disableGutters={true}
          disablePadding={true}
          style={{
            paddingLeft: 5,
          }}
          alignItems="center"
          dense={false}
        >
          <ListItemIcon style={{ color: colors.LandingPage_Description }}>
            {item.item.icon}
          </ListItemIcon>
          <ListItemText
            inset={false}
            disableTypography={false}
            primary={item.item.name}
            style={{
              color: colors.LandingPage_Description,
              textDecoration: 'none !important',
            }}
          />
        </ListItem>
      </NavLink>

      <Divider />
    </div>
  );
};
